import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';

import NavBar from '../components/layout/navbar/_NavBar';
import Footer from '../components/layout/footer/_Footer';

import Appointments from './pages/appointments/_Appointments';
import Preferences from './pages/preferences/_Preferences';

import Logout from './pages/_auth/Logout';

// import AdminProfilePage from './pages/accounts/admin-user-profile/_AdminProfilePage';

import { NotFound, OutOfDate, Watermark } from '../components/display';

import { fetchServerTime, forceSignout } from '../actions';

// App > Routes > BasePage

const currentVersion = '4.0.1';

const cookies = new Cookies();

function BasePage(props){
    const { fetchServerTime, forceSignout } = props;

    const [permissions, setPermissions] = useState(props.auth.permissions);
    const [viewMode, setViewMode] = useState(window.innerWidth >= 992 &&
    window.innerHeight >= 400 ? 'web' : 'mobile');

    useEffect(() => {
        window.addEventListener('resize', () => {
        const newWidth = window.innerWidth;
        const newHeight = window.innerHeight;
        setViewMode(newWidth >= 992 && newHeight >= 400 ? 'web' : 'mobile');
        });  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
 
   // This performs a simple server call (fetchServerTime) to force the browser to check the status of
   // the current token, and either do nothing (if aToken is valid), refersh it (if rToken is valid),
   // or sign the user out
    useEffect(() => {
        (async function getPermissions(){
            if(!props.auth?.sessionId) return; // User should be signed out in this case
        
            await fetchServerTime();
            // Get token and attempt verification
            const aToken = cookies.get('aToken');
        
            if(!props.auth?.sessionId) return; // User should be signed out in this case
            let permissions = null;
        
            try {
                const token = jwt_decode(aToken);
                permissions = token.rpPermissions;
            } catch(err) {
                console.error(err);
                forceSignout();
            } finally {
                setPermissions(permissions);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.auth.sessionId, fetchServerTime]);
    
    const [version, setVersion] = useState(currentVersion);
    
    if(!permissions) return <Navigate replace={true} to="auth/login"/>;
    return (
        <div className="bg-mpLYellow-5">
            <div className="h-full min-h-screen">
                {version !== currentVersion ? <OutOfDate latest={version} current={currentVersion}/> : null}
                <NavBar permissions={permissions}/>
                <Watermark database={props.server.database}/>
                <Routes>

                    {['Parent', 'Student'].includes(permissions) ? 
                        <>
                        <Route path="appointments" element={<Appointments viewMode={viewMode} setVersion={setVersion}/>}/>

                        <Route path="preferences" element={<Preferences viewMode={viewMode} setVersion={setVersion}/>}/>

                        {/* <Route path="/user-profile" element={<AdminProfilePage setVersion={setVersion}/>} /> */}

                        {/* <Route path='/' element={<NotFound/>}/> */}

                        {/* {permissions === 'Root' ? <Route path="/root/action-tracker" element={<ActionTracker setVersion={setVersion}/>} />: null} */}
                        </>
                        : null
                    }
                    <Route path="logout/*" element={<Logout/>}/> 
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </div>
            <Footer version={currentVersion}/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        server: state.server
    }
}

export default connect(mapStateToProps, {
    fetchServerTime,
    forceSignout
})(BasePage);