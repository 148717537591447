import { basicPath } from '../basicPath';

export const fetchMemberRequestsIds = (params) => async (dispatch) => {
    const path = '/rp/member/member-requests/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchMemberRequestsStudentIds = (params) => async (dispatch) => {
    const path = '/rp/member/member-requests/student-ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createMemberRequest = (params) => async (dispatch) => {
    const path = '/rp/member/member-requests';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateMemberRequest = (params) => async (dispatch) => {
    const path = '/rp/member/member-requests';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const updateMemberRequestCancel = (params) => async (dispatch) => {
    const path = '/rp/member/cancel-member-requests';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}