import React, { useState, useRef, useCallback, useEffect } from "react";
import { connect } from 'react-redux';

import { SpinnerLoader } from "../../../components/custom-essentials";
import { LoadingOverlay, BrowserTabTitle } from '../../../components/display';
import { formatDate } from '../../../components/functions';
import { checkResponses } from '../../../components/form';
import { Socket } from '../../../components/ws';
import AppointmentInfo from './components/AppointmentInfo';
import StudentInfo from './components/StudentInfo';
import UpcomingAppointments from './components/UpcomingAppointments';
import MemberRequests from './components/MemberRequests';

import {
    fetchStudentsRelevant,
    fetchCentersAll,
    fetchParentByStudent,
    fetchAppointmentsMemberParentStudent
} from '../../../actions';

const now = new Date();
now.setHours(0, 0, 0, 0);

function Appointments(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Data
    const [centerOptions, setCenterOptions] = useState([{ value: -1, label: 'Loading centers...' }]);
    const [studentOptions, setStudentOptions] = useState([]);
    const [parent, setParent] = useState({});
    const [relevantAppointments, setRelevantAppointments] = useState([]);
    // Data to check available appointments
    //
    // End - check
    
    const { fetchStudentsRelevant, fetchCentersAll, fetchParentByStudent, fetchAppointmentsMemberParentStudent } = props;

    const refreshData = useCallback((newCenterOptions = centerOptions) => {
        (async function refresh(){
            if(loading) return;
            if(mounted.current) setLoading(true);

            const relevantStudentsRes = await fetchStudentsRelevant();
            const newRelevantStudents = relevantStudentsRes.data?.students || [];
            const newRelevantContracts = relevantStudentsRes.data?.contracts || [];
            const newRelevantBookLists = relevantStudentsRes.data?.bookLists || [];
            const newRelevantBookListItems = relevantStudentsRes.data?.bookListItems || [];
            const newRelevantBooks = relevantStudentsRes.data?.books || [];

            const studentContractMap = {};
            newRelevantContracts.forEach(c => {
                const studentId = c.student;
                if(!studentContractMap[studentId]) studentContractMap[studentId] = [];
                studentContractMap[studentId].push(c);
            });
            const bookMap = {};
            newRelevantBooks.forEach(b => bookMap[parseInt(b.id)] = b.title);
            const bookListItemMap = {};
            newRelevantBookListItems.forEach(bli => {
                bli.bookName = bookMap[parseInt(bli.book_id)] || `Unknown book (ID ${bli.book_id})`;
                const bookListId = parseInt(bli.book_list_id);
                if(!bookListItemMap[bookListId]) bookListItemMap[bookListId] = [];
                bookListItemMap[bookListId].push(bli);
            });
            const studentBookListMap = {};
            newRelevantBookLists.forEach(bl => {
                const bookListId = parseInt(bl.id);
                bl.items = bookListItemMap[bookListId] || [];
                if(!studentBookListMap[bl.student]) studentBookListMap[bl.student] = [];
                studentBookListMap[bl.student].push(bl);
            });

            const newStudentOptions = [];
            newRelevantStudents.forEach(s => {
                s.contractList = (studentContractMap[s.user_id] || []).sort((a, b) => {
                    const aDate = new Date(a.start_date);
                    const bDate = new Date(b.start_date);
                    return aDate - bDate;
                }).map(c => {
                    const hoursLeft = (c.minutes_remaining / 60).toLocaleString(undefined, { maximumFractionDigits: 2 });
                    c.contractName = `${formatDate(c.start_date)} to ${formatDate(c.end_date)}: ${c.type} (${hoursLeft} hours remaining)`;
                    return c;
                });
                s.bookListOptions = (studentBookListMap[s.user_id] || []).map(bl => {
                    return { value: bl.id, label: `${bl.name} (Created ${formatDate(bl.date_created)})`, obj: bl };
                });
                newStudentOptions.push({
                    value: s.user_id,
                    label: `${s.first_name} ${s.last_name}${parseInt(s.rp_active) === 0 ? ' (Inactive)' : ''}`,
                    obj: s
                });
            });
    
            const relevantAppointmentsRes = await fetchAppointmentsMemberParentStudent({ userId: props.auth.userId });
            const isApiError = checkResponses(relevantAppointmentsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newRelevantAppointments = relevantAppointmentsRes.data || [];
            const studentMap = {};
            newRelevantStudents.forEach(s => studentMap[s.user_id] = `${s.first_name} ${s.last_name}`);
            const centerMap = {};
            newCenterOptions.forEach(c => centerMap[parseInt(c.value)] = c.label);
            
            const now2 = new Date();
            const relevantAppointmentsAppended = newRelevantAppointments.map(ra => {
                ra.studentName = studentMap[ra.student] || `Unable to find student (UID: ${ra.student})`;
                ra.centerName = centerMap[parseInt(ra.center)] || `Unable to find center (ID: ${ra.center})`;
                const aptDate = new Date(ra.date_time);
                aptDate.setMinutes(aptDate.getMinutes() + parseInt(ra.duration));
                ra.isInFuture = aptDate > now2;

                ra.start = aptDate.getHours() * 60 + aptDate.getMinutes() * 1;
                ra.end = ra.start + parseInt(ra.duration);
                return ra;
            }).sort((a, b) => {
                if(a.studentName < b.studentName) return -1;
                else if(a.studentName > b.studentName) return 1;
                else return 0;
            }).sort((a, b) => {
                return a.date_time - b.date_time;
            });
    
            if(mounted.current){
                setStudentOptions(newStudentOptions);
                setRelevantAppointments(relevantAppointmentsAppended);
                setHasLoaded(true);
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted, loading, centerOptions]);

    useEffect(() => {
        async function init(){
            // Need to load BEFORE setHasLoaded to prevent crashes. Will load twice on initial load (once in init, once in refreshData)
            const centersRes = await fetchCentersAll();
            const isApiError = checkResponses(centersRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }
            
            const newCenters = centersRes.data || [];

            const newCenterOptions = newCenters.map(c => ({ value: parseInt(c.id), label: c.name }));

            if(props.auth.permissions === 'Student'){
                const parentRes = await fetchParentByStudent({ id: props.auth.userId });
                const isApiError = checkResponses(parentRes);
                if(isApiError){
                    if(mounted.current){
                        setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                        setLoading(false);
                        setHasLoaded(true);
                    }
                    return;
                }

                const newParent = parentRes.data?.[0] || {};
                if(mounted.current) setParent(newParent);
            } else {
                const parentRes = await fetchParentByStudent({ id: studentOptions[0]?.parent });
                const isApiError = checkResponses(parentRes);
                if(isApiError){
                    if(mounted.current){
                        setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                        setLoading(false);
                        setHasLoaded(true);
                    }
                    return;
                }

                const newParent = parentRes.data?.[0] || {};
                if(mounted.current) setParent(newParent);
            }

            if(mounted.current){
                setCenterOptions(newCenterOptions);
                refreshData(newCenterOptions);
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <BrowserTabTitle>Appointments and Student Information</BrowserTabTitle>
            {!hasLoaded || loading ? <LoadingOverlay/> : null}
            <>
                {!hasLoaded ? null :
                <>
                {props.viewMode === 'web' ? 
// BEGIN - DESKTOP VIEW //
                <div className="page-box">
                    <div className="flex flex-row">
                        <div className="card w-full">
                            {hasLoaded ? 
                                <StudentInfo
                                    viewMode={props.viewMode}
                                    loading={loading}
                                    hasLoaded={hasLoaded}
                                    refreshData={refreshData}
                                    studentOptions={studentOptions}
                                    apiError={apiError}
                                /> : (
                                    <div>
                                        <span className="ml-3 spinner spinner-mpDBlue" style={{ marginRight: "2rem" }}/>
                                        Loading...
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <br/>

                    <div className="flex flex-row gap-x-4">
                        <div className="card w-[59%]">
                            {hasLoaded ? 
                                <UpcomingAppointments
                                    viewMode={props.viewMode}
                                    loading={loading}
                                    parent={parent}
                                    refreshData={refreshData}
                                    studentOptions={studentOptions}
                                    centerOptions={centerOptions}
                                    relevantAppointments={relevantAppointments}
                                    apiError={apiError}
                                /> : (
                                    <div>
                                        <span className="ml-3 spinner spinner-mpDBlue" style={{ marginRight: "2rem" }}/>
                                        Loading...
                                    </div>
                                )
                            }
                        </div>
                        <div className="card w-[39%]">
                            {hasLoaded ? 
                                <MemberRequests
                                    studentOptions={studentOptions}
                                /> : (
                                    <>
                                        <span className="ml-3 spinner spinner-mpDBlue" style={{ marginRight: "2rem" }}/>
                                        Loading...
                                    </>
                                )
                            }
                        </div>
                    </div>

                    <br/>

                    <div className="flex flex-row">
                        <div className="card w-full">
                            {hasLoaded ? 
                                <AppointmentInfo
                                    viewMode={props.viewMode}
                                    loading={loading}
                                    studentOptions={studentOptions}
                                    relevantAppointments={relevantAppointments}
                                    apiError={apiError}
                                /> : (
                                    <div>
                                        <SpinnerLoader/>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
// END - DESKTOP VIEW //
// BEGIN - MOBILE VIEW //
                : props.viewMode === 'mobile' ?
                <div className="page-box">
                    <div className="card">
                        {hasLoaded ? 
                            <StudentInfo
                                viewMode={props.viewMode}
                                loading={loading}
                                hasLoaded={hasLoaded}
                                refreshData={refreshData}
                                studentOptions={studentOptions}
                                apiError={apiError}
                            /> : (
                                <div>
                                    <span className="ml-3 spinner spinner-mpDBlue" style={{ marginRight: "2rem" }}/>
                                    Loading...
                                </div>
                            )
                        }
                    </div>

                    <br/>

                    <div className="card">
                        {hasLoaded ? 
                            <MemberRequests
                                studentOptions={studentOptions}
                            /> : (
                                <>
                                    <span className="ml-3 spinner spinner-mpDBlue" style={{ marginRight: "2rem" }}/>
                                    Loading...
                                </>
                            )
                        }
                    </div>

                    <br/>

                    <div className="card">
                        {hasLoaded ? 
                            <UpcomingAppointments
                                viewMode={props.viewMode}
                                loading={loading}
                                parent={parent}
                                refreshData={refreshData}
                                studentOptions={studentOptions}
                                centerOptions={centerOptions}
                                relevantAppointments={relevantAppointments}
                                apiError={apiError}
                            /> : (
                                <div>
                                    <span className="ml-3 spinner spinner-mpDBlue" style={{ marginRight: "2rem" }}/>
                                    Loading...
                                </div>
                            )
                        }
                    </div>

                    <br/>

                    <div className="card">
                        {hasLoaded ? 
                            <AppointmentInfo
                                viewMode={props.viewMode}
                                loading={loading}
                                studentOptions={studentOptions}
                                relevantAppointments={relevantAppointments}
                                apiError={apiError}
                            />
                            : (
                                <div>
                                    <span className="ml-3 spinner spinner-mpDBlue" style={{ marginRight: "2rem" }}/>
                                    Loading...
                                </div>
                            )
                        }
                    </div>
                </div>
// END - MOBILE VIEW //
                : null
                }
                </>}
            </>
            <Socket
                refreshData={refreshData}
                page="Appointments and Student Info"
                setVersion={props.setVersion}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchStudentsRelevant,
    fetchCentersAll,
    fetchParentByStudent,
    fetchAppointmentsMemberParentStudent
})(Appointments);