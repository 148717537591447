import { basicPath } from '../basicPath';

export const fetchParentByStudent = (params) => async (dispatch) => {
    const path = '/member/member-users/parent-student';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchMemberSelf = (params) => async (dispatch) => {
    const path = '/member/member-users/self';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const updateMemberSelf = (params) => async (dispatch) => {
    const path = '/member/member-users/self';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}