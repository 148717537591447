import React, { useRef, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { SelectSingle, Check } from '../../../../components/form';
import { getDateObject } from '../../../../components/functions';
import { Table } from '../../../../components/table';
import { getColumnsBookLists } from '../table/getColumns';

const now = new Date();
const defaultSorted = { dataField: 'custom', order: 'asc' };
const defaultBookListOption = { value: -1, label: 'Please select a book list...' };

function StudentInfo(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const { viewMode, hasLoaded, refreshData, loading, studentOptions, apiError } = props;

    const columns = useMemo(() => getColumnsBookLists(), []);
    function getDriveButtonsContractInfo(){
        const ss = formRef?.current?.values?.selectedStudent?.obj;
        if(!ss) return null;

        const studentName = `${ss.first_name} ${ss.last_name}`;
        const driveName1 = ss.rp_drive_folder_link_1_name || 'Unnamed';
        const driveLink1 = ss.rp_drive_folder_link_1;
        const driveName2 = ss.rp_drive_folder_link_2_name || 'Unnamed';
        const driveLink2 = ss.rp_drive_folder_link_2;
        const driveName3 = ss.rp_drive_folder_link_3_name || 'Unnamed';
        const driveLink3 = ss.rp_drive_folder_link_3;
        const driveName4 = ss.rp_drive_folder_link_4_name || 'Unnamed';
        const driveLink4 = ss.rp_drive_folder_link_4;

        const driveButton1 = driveLink1 ? (
            <Button
                color="lte-mpEGreen"
                className="mr-2"
                onClick={() => window.open(driveLink1, '_blank')}
            >
                {driveName1}
            </Button>
        ) : null;
        const driveButton2 = driveLink2 ? (
            <Button
                color="lte-mpEGreen"
                className="mr-2"
                onClick={() => window.open(driveLink2, '_blank')}
            >
                {driveName2}
            </Button>
        ) : null;
        const driveButton3 = driveLink3 ? (
            <Button
                color="lte-mpEGreen"
                className="mr-2"
                onClick={() => window.open(driveLink3, '_blank')}
            >
                {driveName3}
            </Button>
        ) : null;
        const driveButton4 = driveLink4 ? (
            <Button
                color="lte-mpEGreen"
                className="mr-2"
                onClick={() => window.open(driveLink4, '_blank')}
            >
                {driveName4}
            </Button>
        ) : null;

        const driveButtons = driveButton1 || driveButton2 || driveButton3 || driveButton4 ? 
            (
                <>
                    <div className="h-4 clear-both"/>
                    <hr/>
                    <div className="h-4 clear-both"/>
                    <h4>{studentName}'s Folders</h4>
                    <div className="h-4 clear-both"/>
                    <div className="row no-mar">
                        {driveButton1}
                        {driveButton2}
                        {driveButton3}
                        {driveButton4}
                    </div>
                </>
            ) : null;

        const showActiveOnly = formRef?.current?.values?.showActiveOnly && formRef?.current?.values?.showActiveOnly !== 'false';
        const contractList = ss.contractList.filter(c => {
            return !showActiveOnly || parseInt(c.active) === 1;
        });
        const contractInfo = contractList ? (
            <>
                <hr/>

                <div className="h-4 clear-both"/>
                
                <h4>{studentName}'s Contracts</h4>
                <div className="h-4 clear-both"/>
                {contractList.map(c => {
                    const inactiveText = parseInt(c.active) === 1 ? null : (
                        <span className="mp-text-mpLRed">&nbsp;(Inactive)</span>
                    );
                    return (
                        <div key={`student-info-contract-list-${c.id}`}>
                            <h6 style={{ marginLeft: "1rem" }}>
                                {c.contractName}{inactiveText}
                            </h6>
                        </div>
                    );
                })}
            </>
        ) : null;

        if(driveButtons || contractInfo){
            return (
                <>
                    {driveButtons}

                    <br/>

                    {contractInfo}
                </>
            );
        } else return null;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    if(apiError) return <div className="mp-text-mpLRed">{apiError}</div>
    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    selectedDate: getDateObject(now),
                    selectedStudent: studentOptions[0]?.value ? studentOptions[0] : hasLoaded ? { value: -1, label: 'No students found' } : { value: -1, label: 'Loading students...'},
                    selectedBookList: studentOptions[0]?.obj?.bookLists?.[0] || defaultBookListOption,
                    showActiveOnly: true,
                }}
                onSubmit={refreshData}
                innerRef={formRef}
            >
                {formik => (
                    <>
                        { viewMode === 'web' ?
                            <>
                                <h2>Student Information</h2>

                                <br/>
            
                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <SelectSingle
                                            id="scheduling-student-1"
                                            label="Student"
                                            name="selectedStudent"
                                            value={formik.values.selectedStudent}
                                            onChange={formik.handleChange}
                                            disabled={loading}
                                            options={studentOptions}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-2/3">
                                        <SelectSingle
                                            id="scheduling-book-list-1"
                                            label="Book List"
                                            name="selectedBookList"
                                            value={formik.values.selectedBookList}
                                            onChange={formik.handleChange}
                                            disabled={loading}
                                            options={formik.values.selectedStudent.obj?.bookListOptions}
                                        />
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols gap-y-2">
                                        <Check
                                            id="scheduling-show-active-only"
                                            name="showActiveOnly"
                                            label="Hide Inactive Contracts"
                                            color="mpDBlue"
                                            checked={formik.values.showActiveOnly}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>

                                {getDriveButtonsContractInfo()}

                                <div>
                                    {formik.values.selectedBookList.obj ?
                                        <>
                                            <h4>Book List: {formik.values.selectedBookList.obj.name}</h4>
                                
                                            <br/>
                                
                                            <Table
                                                tableName="appointments-studentInfo"
                                                data={formik.values.selectedBookList.obj.items}
                                                columns={columns}
                                                defaultSorted={defaultSorted}
                                                rowsPerPageInitial={50}
                                                emptyMessage="No books were found"
                                            />
                                        </> : null
                                    }
                                </div>
                            </> :
                            viewMode === 'mobile' ?
                            <>
                                <h2>Student Information</h2>

                                <br/>

                                <h4>Student</h4>
                                <SelectSingle
                                    id="scheduling-student-1"
                                    label="Student"
                                    name="selectedStudent"
                                    value={formik.values.selectedStudent}
                                    onChange={formik.handleChange}
                                    disabled={loading}
                                    options={studentOptions}
                                />

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols gap-y-2">
                                        <Check
                                            id="scheduling-show-active-only"
                                            name="showActiveOnly"
                                            label="Hide Inactive Contracts"
                                            color="mpDBlue"
                                            checked={formik.values.showActiveOnly}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>

                                {getDriveButtonsContractInfo()}

                                <br/>

                                <hr/>

                                <br/>

                                <h4>Book List</h4>
                                <SelectSingle
                                    id="scheduling-book-list-1"
                                    label="Book List"
                                    name="selectedBookList"
                                    value={formik.values.selectedBookList}
                                    onChange={formik.handleChange}
                                    disabled={loading}
                                    options={formik.values.selectedStudent.obj?.bookListOptions}
                                />

                                <br/>

                                <div>
                                    {formik.values.selectedBookList.obj ?
                                        <>
                                            <h4>Book List: {formik.values.selectedBookList.obj.name}</h4>
                                
                                            <br/>
                                
                                            <Table
                                                data={formik.values.selectedBookList.obj.items}
                                                columns={columns}
                                                defaultSorted={defaultSorted}
                                                rowsPerPageInitial={50}
                                                emptyMessage="No books were found"
                                            />
                                        </> : null
                                    }
                                </div>
                            </> :
                            null
                        }
                    </>
                )}
            </Formik>
        </div>
    );
}

export default connect(null, {

})(StudentInfo);