import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button, ModalBodyFooter, ErrorMessage, ModalLoading, ModalProcessing } from '../../custom-essentials';
import { SelectSingle, FormikControl } from '../../form';
import { validationSchema, getInitStudentOption } from './helpers';

function MemberRequestsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedRequest, studentOptions, loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <h4>Delete this request?</h4>
                            <div className="grid grid-cols-1 gap-y-2 w-full">
                                <div>Student: {selectedRequest?.studentName}</div>
                                <div>Message: {selectedRequest?.message}</div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed font-sm mr-8">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="hol-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Delete
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    selectedStudent: getInitStudentOption(selectedRequest?.student, studentOptions),
                    message: selectedRequest?.message || ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                        >
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Student</h4>
                                    <SelectSingle
                                        id="member-requests-selectedStudent"
                                        name="selectedStudent"
                                        value={formik.values.selectedStudent}
                                        onChange={formik.handleChange}
                                        options={studentOptions}
                                    />
                                    {formik.errors.selectedStudent ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.selectedStudent}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Message</h4>
                                    <FormikControl
                                        id="member-requests-message"
                                        name="message"
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        as="textarea"
                                        placeholder="Message to send to ReadingPlex instructors. Max 500 characters."
                                    />
                                    {formik.errors.message ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.message}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed mr-2">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed mr-8">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(MemberRequestsBodyFooter);