import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button, EllipsisLoader } from '../../../components/custom-essentials';
import { checkResponse, Check, checkResponses } from '../../../components/form';
import { LoadingOverlay, TooltipWrapper, BrowserTabTitle, renderPhone } from '../../../components/display';
import { Socket } from '../../../components/ws';

import {
    fetchMemberSelf,
    updateMemberSelf
} from '../../../actions';

function Preferences(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [memberInfo, setMemberInfo] = useState({});
    const [changesMade, setChangesMade] = useState(false);
    const [success, setSuccess] = useState(false);

    const { fetchMemberSelf, updateMemberSelf } = props;

    const refreshData = useCallback(() => {
        (async function(){
            if(loading) return;
            setLoading(true);

            const memberInfoRes = await fetchMemberSelf();
            const isApiError = checkResponses(memberInfoRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newMemberInfo = memberInfoRes.data?.[0] || {};

            if(mounted.current){
                setMemberInfo(newMemberInfo);
                setLoading(false);
            }
        })();
    }, [mounted, loading, fetchMemberSelf]);
    useEffect(() => {
        async function init(){
            if(mounted.current){
                refreshData();
            }
        }
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { receiveEmails, receiveTexts } = values;
            const { setStatus, setSubmitting } = actions;
            
            setSubmitting(true);

            const params = {
                receiveEmails: receiveEmails === true ? 1 : 0,
                receiveTexts: receiveTexts === true ? 1 : 0
            };
            const response = await updateMemberSelf(params);
            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid){
                if(mounted.current) setSubmitting(false);
                return;
            }

            if(mounted.current){
                setChangesMade(false);
                refreshData();
                setSubmitting(false);
                setSuccess(true);
                setTimeout(() => {
                    if(mounted.current) setSuccess(false);
                }, 2500);
                return;
            }
        }
        submit();
    }, [mounted, updateMemberSelf, refreshData]);
    const handleSwitches = useCallback((e, values, initialValues) => {
        let { receiveEmails, receiveTexts } = values;
        // Take into account the requested change
        if(e.target.name === 'receiveEmails') receiveEmails = !receiveEmails;
        else if(e.target.name === 'receiveTexts') receiveTexts = !receiveTexts;

        const receiveEmailsInit = initialValues.receiveEmails;
        const receiveTextsInit = initialValues.receiveTexts;

        const changesExist = receiveEmails !== receiveEmailsInit || receiveTexts !== receiveTextsInit;

        if(mounted.current){
            setChangesMade(changesExist);
        }
    }, [mounted])

    return (
        <div className="page-box">
            <BrowserTabTitle>Preferences</BrowserTabTitle>
            <div className="card">
                {loading ? <LoadingOverlay/> : null}

                <h2>Your Account</h2>
                
                {apiError ? <div className="text-mpLRed">{apiError}</div> : 
                    <Formik
                        enableReinitialize
                        initialValues={{
                            receiveEmails: parseInt(memberInfo.receive_emails) === 1 ? true : false,
                            receiveTexts: parseInt(memberInfo.receive_texts) === 1 ? true : false,
                        }}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <>
                                {loading ? 
                                    <h4><EllipsisLoader/>Loading...</h4>
                                    :
                                    <div className="col">
                                        <div>Name: {memberInfo.first_name} {memberInfo.last_name}</div>
                                        <div>Email: {memberInfo.email}</div>
                                        <div>Phone: {renderPhone(memberInfo.phone)}</div>
                                    </div>
                                }

                                <br/>

                                <h2>Communication Preferences</h2>
                                    {loading ? 
                                        <h4><EllipsisLoader/>Loading...</h4>
                                        :
                                        <div className="flex flex-row gap-x-4">
                                            <Check
                                                id="prefs-receive-emails"
                                                name="receiveEmails"
                                                color="mpDBlue"
                                                onChange={(e) => {
                                                    handleSwitches(e, formik.values, formik.initialValues);
                                                    formik.handleChange(e);
                                                }}
                                                checked={formik.values.receiveEmails}
                                                label={
                                                    <TooltipWrapper
                                                        tooltipText={
                                                            <>
                                                                <div>If enabled, you will receive automated email reminders the day before each appointment.</div>
                                                                <br/>
                                                                <div>You may also receive other communications from MathPlex.</div>
                                                            </>
                                                        }
                                                    >
                                                        <div className="text-mpLBlue">Receive Emails</div>
                                                    </TooltipWrapper>
                                                }
                                            />
                                            <Check
                                                id="prefs-receive-texts"
                                                name="receiveTexts"
                                                color="mpDBlue"
                                                onChange={(e) => {
                                                    handleSwitches(e, formik.values, formik.initialValues);
                                                    formik.handleChange(e);
                                                }}
                                                checked={formik.values.receiveTexts}
                                                label={
                                                    <TooltipWrapper
                                                        tooltipText={
                                                            <>
                                                                <div>If enabled, you will receive automated text messages 30 minutes before each appointment.</div>
                                                            </>
                                                        }
                                                    >
                                                        <div className="text-mpLBlue">Receive Texts</div>
                                                    </TooltipWrapper>
                                                }
                                            />
                                        </div>
                                    }

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <Button
                                        disabled={loading || formik.isSubmitting || !changesMade}
                                        onClick={formik.handleSubmit}
                                        color="lte-mpLBlue"
                                    >
                                        Save Changes
                                    </Button>
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        {changesMade && !formik.isSubmitting ?
                                            <div className="text-mpOrange d-flex align-self-center">
                                                Warning! You have unsaved changes.
                                            </div>
                                            : formik.isSubmitting ?
                                            <div className="row d-flex align-self-center">
                                                <span className="ml-3 spinner spinner-mpLRed" style={{ marginRight: "2rem" }}/>
                                                Saving changes...
                                            </div>
                                            : null
                                        }
                                        {formik.status && !formik.isSubmitting ? 
                                            <div className="text-mpLRed d-flex align-self-center">
                                                {formik.status}
                                            </div> : null
                                        }
                                        {success ? 
                                            <div className="text-mpGreen d-flex align-self-center">
                                                Successfully updated prefernces!
                                            </div> : null
                                        }
                                    </div>
                                </div>
                            </>
                        )}
                    </Formik>
                }
            </div>
            <Socket
                refreshData={refreshData}
                page="Preferences"
                setVersion={props.setVersion}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchMemberSelf,
    updateMemberSelf
})(Preferences);