import React from 'react';

import { Button, SVGIcon } from '../../../../components/custom-essentials';
import { formatDateFull, formatDate, formatTime } from '../../../../components/functions';
import { renderStatus, TooltipWrapper } from '../../../../components/display';

const statusSortObject = {
    'Not Charged': 1,
    'Missed': 2,
    'Cancelled': 3,
    'Scheduled': 4,
    'In Progress': 5,
    'Completed': 6
};
const noneTag = <span className="text-mpOrange">None</span>;

export function getColumnsBookLists(){
    return (
        [
            {
                dataField: 'bookName',
                text: 'Book Name',
                sort: true
            },
            {
                dataField: 'date_assigned',
                text: 'Date Assigned',
                sort: true,
                sortFunc: (a, b, order) => {
                    const swap = order === 'asc' ? 1 : -1;
                    const aDate = new Date(a);
                    const bDate = new Date(b);
                    return (aDate - bDate) * swap;                
                },
                formatter: (row) => {
                    const cell = row.date_assigned;
                    if(!cell) return <div className="text-mpOrange">Never</div>;
                    return <div>{formatDate(cell)}</div>;
                }
            },
            {
                dataField: 'date_completed',
                text: 'Date Completed',
                sort: true,
                sortFunc: (a, b, order) => {
                    const swap = order === 'asc' ? 1 : -1;
                    const aDate = new Date(a);
                    const bDate = new Date(b);
                    return (aDate - bDate) * swap;
                },
                formatter: (row) => {
                    const cell = row.date_completed;
                    if(!cell) return <div className="text-mpOrange">Never</div>;
                    return <div>{formatDate(cell)}</div>;
                }
            },
        ]
    );
}

export function getColumnsUpcoming(){
    return(
        [
            {
                dataField: 'studentName',
                text: 'Student',
                sort: true,
            }, {
                dataField: 'date_time',
                text: 'Date & Time',
                sort: true,
                sortFunc: (a, b, order) => {
                    const swap = order === 'asc' ? 1 : -1;
                    const aDate = new Date(a);
                    const bDate = new Date(b);
                    return (aDate - bDate) * swap;
                },
                formatter: (row) => {
                    return(
                        <>
                            <div>{formatDateFull(row.date_time)}</div>
                            <div>{formatTime(row.start)} to {formatTime(parseInt(row.end))}</div>
                            <div>{row.duration} minutes</div>
                        </>
                    );
                }
            }, {
                dataField: 'centerName',
                text: 'Center',
                sort: true
            }, {
                dataField: 'status',
                text: 'Status',
                sort: true,
                sortFunc: (a, b, order) => {
                    const swap = order === 'asc' ? 1 : -1;
                    return (statusSortObject[a] - statusSortObject[b]) * swap;
                },
                formatter: (row) => renderStatus(row)
            }
        ]
    );
}

export function getColumnsPast(viewMode){
    return(
        [
            {
                dataField: 'studentName',
                text: 'Student',
                sort: true,
            },
            {
                dataField: 'date_time',
                text: 'Date',
                sort: true,
                sortFunc: (a, b, order) => {
                    const swap = order === 'asc' ? 1 : -1;
                    const aDate = new Date(a);
                    const bDate = new Date(b);
                    return (aDate - bDate) * swap;                
                },
                formatter: (row) => {
                    return(
                        <>
                            <div>{formatDateFull(row.date_time)}</div>
                        </>
                    );
                }
            },
            {
                dataField: 'info',
                text: 'Session Info',
                formatter: (row) => {
                    renderStatus(row)
                    return (
                        <>
                            {viewMode === 'web' ? 
                                (
                                    <>
                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                            <h6><b>Time</b></h6>
                                            <div>{formatTime(row.start)} to {formatTime(parseInt(row.end))} ({row.duration} minutes)</div>
                                            <div>
                                                {row.instructorNames === 'None' ? noneTag : row.instructorNames}
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                            <h6><b>Center</b></h6>
                                            <div>{row.centerName}</div>
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                            <h6><b>Status</b></h6>
                                            <div>{renderStatus(row)}</div>
                                        </div>
                                    </div>

                                    <div className="h-2 clear-both"/>

                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                            <div className="flex flex-row items-center">
                                                <TooltipWrapper
                                                    tooltipText="Graded on a scale of 1 to 4"
                                                >
                                                    <h6 className="text-mpLBlue"><b>Participation:</b></h6>
                                                </TooltipWrapper> &nbsp;
                                                <h6>{row.participation}</h6>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                            <div className="flex flex-row items-center">
                                                <TooltipWrapper
                                                    tooltipText="Graded on a scale of 1 to 4"
                                                >
                                                    <h6 className="text-mpLBlue"><b>Answer Accuracy:</b></h6>
                                                </TooltipWrapper> &nbsp;
                                                <h6>{row.answer_accuracy}</h6>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                            <div className="flex flex-row items-center">
                                                <TooltipWrapper
                                                    tooltipText="Graded on a scale of 1 to 4"
                                                >
                                                    <h6 className="text-mpLBlue"><b>Depth of Thinking:</b></h6>
                                                </TooltipWrapper> &nbsp;
                                                <h6>{row.depth_of_thinking}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                ) :
                                (
                                    <>
                                        <div className="flex flex-row gap-x-4">
                                            <div className="grid grid-cols-1 gap-y-2 w-full">
                                                <h6><b>Time</b></h6>
                                                <div>{formatTime(row.start)} to {formatTime(parseInt(row.end))} ({row.duration} minutes)</div>
                                                <div>
                                                    {row.instructorNames === 'None' ? noneTag : row.instructorNames}
                                                </div>
                                            </div>
                                        </div>

                                        <br/>

                                        <div className="flex flex-row gap-x-4">
                                            <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                                <h6><b>Center</b></h6>
                                                <div>{row.centerName}</div>
                                            </div>
                                            <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                                <h6><b>Status</b></h6>
                                                <div>{renderStatus(row)}</div>
                                            </div>
                                        </div>

                                        <div className="h-2 clear-both"/>

                                        <div className="flex flex-row">
                                            <TooltipWrapper
                                                tooltipText="Graded on a scale of 1 to 4"
                                            >
                                                <h6 className="text-mpLBlue"><b>Participation:</b></h6>
                                            </TooltipWrapper> &nbsp;
                                            <h6>{row.participation}</h6>
                                        </div>
                                        <div className="flex flex-row">
                                            <TooltipWrapper
                                                tooltipText="Graded on a scale of 1 to 4"
                                            >
                                                <h6 className="text-mpLBlue"><b>Answer Accuracy:</b></h6>
                                            </TooltipWrapper> &nbsp;
                                            <h6>{row.answer_accuracy}</h6>
                                        </div>
                                        <div className="flex flex-row">
                                            <TooltipWrapper
                                                tooltipText="Graded on a scale of 1 to 4"
                                            >
                                                <h6 className="text-mpLBlue"><b>Depth of Thinking:</b></h6>
                                            </TooltipWrapper> &nbsp;
                                            <h6>{row.depth_of_thinking}</h6>
                                        </div>
                                    </>
                                )
                            }

                            <div className="h-2 clear-both"/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-1 w-full">
                                    <h6><b>Session Notes</b></h6>
                                    <div>{row.session_notes || noneTag}</div>
                                </div>
                            </div>

                            <div className="h-2 clear-both"/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-1 w-full">
                                    <h6><b>Homework Notes</b></h6>
                                    <div>{row.homework_notes || noneTag}</div>
                                </div>
                            </div>
                        </>
                    )
                }
            },
        ]
    );
}

export function getColumnsRequests(handleShowModal){
    return(
        [
            {
                dataField: 'details',
                text: 'Details (Date Created)',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const swap = order === 'asc' ? 1 : -1;
                    const aDate = new Date(rowA.date_created);
                    const bDate = new Date(rowB.date_created);
                    return (aDate - bDate) * swap;                
                },
                formatter: (row) => {
                    return(
                        <>
                            <div className="flex flex-row">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <div className="flex flex-row">
                                        <b>Student:</b>&nbsp;{row.studentName}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <div className="flex flex-row">
                                        <b>Created:</b>&nbsp;{formatDate(row.date_created)}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <div className="flex flex-row">
                                        <b>Status:</b>&nbsp;{renderStatus(row)}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <div className="flex flex-row">
                                        <b>Message:</b>&nbsp;{row.message}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <div className="flex flex-row">
                                        <b>Date Processed:</b>&nbsp;{row.date_processed ? formatDate(row.date_processed) :
                                            <div className="text-mpOrange">Never</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <div className="flex flex-row">
                                        <b>Admin response:</b>&nbsp;{row.admin_resposne || noneTag}
                                    </div>
                                </div>
                            </div>

                            {row.status === 'Pending' ? (
                                <>
                                    <br/>

                                    <div className="flex flex-row gap-x-2">
                                        <TooltipWrapper
                                            tooltipText={`Edit this request`}
                                        >
                                            <Button
                                                className="btn btn-icon btn-sm"
                                                color="lte-mpLBlue"
                                                variant="icon"
                                                onClick={() => handleShowModal('edit', row)}
                                            >
                                                <SVGIcon fileName="pencil"/>
                                            </Button>
                                        </TooltipWrapper>
                                        <TooltipWrapper
                                            tooltipText={`Delete this request`}
                                        >
                                            <Button
                                                className="btn btn-icon btn-sm"
                                                color="lte-mpLRed"
                                                variant="icon"
                                                onClick={() => handleShowModal('delete', row)}
                                            >
                                                <SVGIcon fileName="trash"/>
                                            </Button>
                                        </TooltipWrapper>
                                    </div>
                                </>
                            ) : null}
                        </>
                    );
                }
            },
        ]
    );
}