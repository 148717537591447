import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import { BrowserTabTitle } from '../../../components/display';
import { Alert, Button, FormValidation, EllipsisLoader } from '../../../components/custom-essentials';
import { FormikControl, checkResponse } from '../../../components/form';

import {
    login
} from '../../../actions';

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required('Required Field'),
    password: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required('Required field'),
});

function Login(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: LoginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            await props.login(values.email, values.password)
                .then(async (response) => {
                    if(mounted.current){
                        setSubmitting(false);
                        checkResponse(response, mounted, setStatus, { hideResponseCode: true, loginMuteOnSuccess: true });
                    }
                })
                .catch((response) => {
                    console.error(response);
                    if(mounted.current){
                        setSubmitting(false);
                        setStatus(`Unexpected error (${response && response.status})`);
                    }
                })
        },
    });

    return (
        <div>
            <BrowserTabTitle content="Member Login"/>
            {/* Headers */}
            <div className="text-3xl">
                Member Login
            </div>
            <div className="text-md text-center relative top-2 text-mpLGrey-3">
                Enter your email and password
            </div>
            <div className="relative top-24">
                <div className="relative">
                    {formik.status ?
                        <Alert color="mpLRed" content={formik.status}/>
                    :
                        <Alert color="mpLBlue" content={"Welcome back!"}/>
                    }
                </div>
                <div className="relative top-10">
                    {/*begin::Form*/}
                    <form
                        onSubmit={formik.handleSubmit}
                    >
                        <div className="grid grid-rows-2 place-items-center">
                            <div className="relative w-1/2">
                                <FormikControl
                                    id="login-email"
                                    name="email"
                                    placeholder="Email"
                                    disabled={formik.isSubmitting}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <FormValidation color="mpLRed" content={formik.errors.email}/>
                                ) : null}
                            </div>
                            <div className="top-3 relative w-1/2">
                                <FormikControl
                                    id="login-password"
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    disabled={formik.isSubmitting}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    shouldHandleSubmit
                                    onSubmit={formik.handleSubmit}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <FormValidation color="mpLRed" content={formik.errors.password}/>
                                ) : null}
                            </div>
                        </div>
                        <div className="relative top-6">
                            <Button
                                color="lte-mpTeal"
                                onClick={() => null}
                                className="mr-4"
                            >
                                <Link
                                    disabled={formik.isSubmitting}
                                    to="/auth/forgot-password"
                                >
                                    Forgot Password
                                </Link>
                            </Button>
                            <Button
                                color="lte-mpLBlue"
                                disabled={formik.isSubmitting}
                                onClick={formik.handleSubmit}
                            >
                                Sign In
                            </Button>{formik.isSubmitting && <EllipsisLoader/>}
                        </div>
                    </form>
                </div>
                {/*end::Form*/}
            </div>
        </div>
    );
}

export default connect(null, {
    login
})(Login);