import React from 'react';

const versionHistory = {
    "Admin 2.2": (
        <>
            <h5>v2.2.3</h5>
            <h6>Release Date: 12/21/22</h6>
            <ul>
                <li>Split the general Active value on Members and Leads into separate MP Active and RP Active values. This will allow better control of what users can access based on which programs they are enrolled in at any given time.</li>
            </ul>

            <br/>

            <h5>v2.2.2</h5>
            <h6>Release Date: 11/2/22</h6>
            <ul>
                <li>Removed Unknown Database "null" message from login screen</li>
                <li>Employees: Show Inactive set to off by default</li>
                <li>Cycle Group Form: Changed maximum number of students to group from 5 to 6</li>
            </ul>

            <br/>

            <h5>v2.2.1</h5>
            <h6>Release Date: 8/25/22</h6>
            <ul>
                <li>Appointment Form: Changed time increment from 30 minutes to 15 minutes.</li>
                <li>Group Form: Changed start time increment from 30 minutes to 15 minutes.</li>
            </ul>

            <br/>

            <h5>v2.2.0</h5>
            <h6>Release Date: 8/18/22</h6>
            <ul>
                <li>Added Member Request notifications and a panel in the sidebar</li>
                <li>Added 3 extra drive links for a total of 4 per student</li>
                <li>"Current contract" renamed to "primary contract"</li>
                <li>Book List Manager: Fixed an issue with the selected group resetting after submiting a form</li>
                <li>Book List Manager: Will show an error if the selected student's info does not load properly. Previously, the form would just return an error on submission</li>
                <li>Book List Manager: Fixed an issue with "No book lists" being shown sometimes when selecting a student for the first time</li>
                <li>Details Form: Removed MP instructors from the list of assigned instructors</li>
                <li>Appointment Form: Added contract selector</li>
                <li>Contract Form: Fixed values (such as hours remaining) loading in as the default value if they were previously at 0 (eg 0 hours loads as 8 hours)</li>
                <li>Contracts and Payents: Temporarily removed the Warnings tab due to incompatibility with the new contract system</li>
                <li>Appointment Form: Added "Force Delete" button that shows up if the normal "Delete" action fails. This will delete the selected appointment without updating contract hours.</li>
            </ul>
        </>
    ),
    "Member 2.0": (
        <>
            <h5>v2.0.1</h5>
            <h6>Release Date: 12/21/22</h6>
            <ul>
                <li>Added RP Active compatibility (see Admin 2.2.3 release notes).</li>
            </ul>

            <h5>v2.0.0</h5>
            <h6>Release Date: Unknown</h6>
            <ul>
                <li>Initial v2 release.</li>
            </ul>
        </>
    ),
    "Admin 2.1": (
        <>
            <h5>v2.1.0</h5>
            <h6>Release Date: 7/28/22</h6>
            <ul>
                <li>Contract Form: Fixed hours remaining not loading properly</li>
                <li>Added sort function to warnings column on warnings page</li>
                <li>Added "Low Minutes" warning</li>
                <li>Details Form: Unassigned books are hidden from view</li>
                <li>Adjusted Book Lists so that they are tied to each student, rather than to a group</li>
                <li>Details Form: Added "Homework Notes" field</li>
                <li>Group Manager: Added option to hide inactive groups</li>
            </ul>
        </>
    ),
    "Admin 2.0": (
        <>
            <h5>v2.0.3</h5>
            <h6>Release Date: 7/27/22</h6>
            <ul>
                <li>Contract Form: Fixed payment per cycle defaulting to 300 if it was set to 0 (contract edit mode)</li>
                <li>Members: Added options to filter by MP/RP</li>
                <li>Details Form: Fixed crash on form submission when a student has no assigned book lists</li>
                <li>Group Manager: Corrected button tooltips (previously "edit flag" and "delete flag")</li>
                <li>Added "Drive Folder Link" to Student Form (Accounts &gt; Members &gt; Students)</li>
                <ul>
                    <li>This button shows up on the Student Table (Accounts &gt; Members &gt; Students) and Scheduling Page (Appointments &gt; Scheduling)</li>
                </ul>
                <li>Book List Form: Fixed an issue with the form crashing in create mode for certain groups with no existing book lists</li>
                <li>Contract Form: Fixed payment per cycle not rendering properly</li>
            </ul>

            <h5>v2.0.2</h5>
            <h6>Release Date: 7/5/22</h6>
            <ul>
                <li>Details Form: Added Participation, Answer Accuracy, Depth of Thinking scores; Improvement Needed Notes, Shown Improvement Notes</li>
                <li>Books: Added "TP" type option</li>
            </ul>

            <h5>v2.0.1</h5>
            <h6>Release Date: 7/1/22</h6>
            <ul>
                <li>Contracts-Warnings: Removed non-RP students from the list</li>
                <li>Payment Form: Fixed a server error that occurred when deleting a payment</li>
                <li>Book Lists: Fixed issue with page crashing after selecting a group</li>
            </ul>

            <h5>v2.0.0</h5>
            <h6>Release Date: 6/30/22</h6>
            <ul>
                <li>Initial v2 release.</li>
            </ul>
        </>
    ),
};

export function VersionHistory(props){
    return versionHistory[props.version] || <h4>Unknown version</h4>
}