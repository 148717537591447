import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { Button, SpinnerLoader } from '../../../components/custom-essentials';
import { BrowserTabTitle } from '../../../components/display';

import {
    signout,
    forceSignout
} from '../../../actions';

const cookies = new Cookies();
class Logout extends Component {
    _isMounted = false;

    constructor(props){
        super(props);

        this.state = {
            rToken: null,
            loaded: false,
            force: false
        }

        this.forceSignout = this.forceSignout.bind(this);
    }

    async componentDidMount(){
        this._isMounted = true;

        const rToken = cookies.get('rToken');
        this.setState({ rToken });
        // if(rToken !== 'null') await this.props.attemptAction(this.props.signout);
        await this.props.signout();
        if(this._isMounted){
            this.setState({ loaded: true });
            this.forceSignout();
        } 
    }

    forceSignout(){
        this.props.forceSignout();
        this.setState({ force: true });
    }

    render() {
        return (
            <>
                <BrowserTabTitle content="Logout"/>
                {(this.state.loaded && cookies.get('rToken') === 'null') || this.state.force ? 
                    <Navigate replace={true} to="/auth/login"/> : 
                    <div style={{ width: "80%", marginTop: "3rem", marginLeft: "10%" }}>
                        {!this.state.loaded ? 
                            <>
                                <SpinnerLoader/>&emsp;&emsp;&emsp;
                                <span><h2>Signing you out...</h2></span>
                            </>
                            :
                            <>
                                <span><h2>Error signing out</h2></span>&emsp;&emsp;&emsp;
                                <Button
                                    color="lte-mpLRed"
                                    onClick={this.forceSignout}
                                >
                                    Force signout
                                </Button>
                            </>
                        }
                    </div>
                }
            </>
        );
    }

    componentWillUnmount(){
        this._isMounted = false;
    }
}

export default connect((state) => { return { auth: state.auth } }, {
    signout,
    forceSignout
})(Logout);
