import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { toAbsoluteUrl } from '../../functions';
import { Button } from '../../custom-essentials';
import MenuItem from './components/MenuItem';

import {

} from '../../../actions';

function NavBar(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { permissions } = props;

    return (
        <div>
            {/* <div className="fixed inset-0 pointer-events-auto" onClick={() => setActiveItem('')}/> */}
            <div className="sticky top-0 w-screen h-16 bg-black flex z-[2000]">
                {/* Main NavBar */}
                <div className="flex flex-row gap-x-2 w-screen">
                    <div className="relative">
                        <img
                            alt="logo"
                            className="h-12 mt-2 mx-4"
                            src={toAbsoluteUrl("/media/logos/darklong.png")}
                        />
                    </div>
                    {['Parent', 'Student'].includes(permissions) ? (
                        <>
                            <div className="flex">
                                <MenuItem
                                    label="Appointments"
                                    to="/appointments"
                                />
                            </div>
                            <div className="flex">
                                <MenuItem
                                    label="Preferences"
                                    to="/preferences"
                                />
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="mr-8 self-center shrink-0">
                    <Button
                        color="hol-mpLRed"
                        onClick={() => null}
                    >
                        <Link to="/logout">
                            Log Out
                        </Link>
                    </Button>
                </div>
            </div>
            {/* Post-Navbar Spacer
            <div className="pb-12 bg-mpLYellow-5"/> */}
        </div>
    );
}

export default connect(null, {

})(NavBar);