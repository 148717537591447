import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { getColumnsRequests } from '../table/getColumns';
import { Switch, checkResponse } from '../../../../components/form';
import { Table } from '../../../../components/table';
import { MemberRequestModal } from '../../../../components/modal';

import {
    fetchMemberRequestsStudentIds
} from '../../../../actions';

const defaultSorted = { dataField: 'details', order: 'asc' };

function MemberRequests(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { studentOptions, fetchMemberRequestsStudentIds } = props;

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [requests, setRequests] = useState([]);

    const [modalMode, setModalMode] = useState(null);
    const [selectedRequest, setSelectedRequest] = useState(null);

    const refreshData = useCallback(() => {
        (async function refresh(){
            if(loading) return;
            if(mounted.current) setLoading(true);

            const studentIds = studentOptions.map(s => s.obj.user_id);
            const requestsRes = await fetchMemberRequestsStudentIds({ studentIds });
            const responseValid = checkResponse(requestsRes, mounted, () => null);
            if(!responseValid && mounted.current){
                setApiError(true);
                setLoading(false);
                return;
            }
            const newRequests = typeof(requestsRes.data) === 'object' ? requestsRes.data : [];

            const studentNameMap = {};
            studentOptions.forEach(s => {
                const studentObj = s.obj || {};
                studentNameMap[studentObj.user_id] = `${studentObj.first_name} ${studentObj.last_name}`;
            });
            newRequests.forEach(r => {
                r.studentName = studentNameMap[r.student] || `Unknown student (UID ${r.student})`;
            });

            if(mounted){
                setRequests(newRequests);
                setHasLoaded(true);
                setLoading(false);
            }
        })();
    }, [loading, fetchMemberRequestsStudentIds, studentOptions])
    useEffect(() => {
        (async function init(){
            refreshData();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function handleShowModal(mode, newSelectedRequest = null){
        setSelectedRequest(newSelectedRequest);
        setModalMode(mode);
        setShowModal(true);
    }
    function onSubmitCallback(changes = true){
        setShowModal(false);
        setSelectedRequest(null);
        setModalMode(null);
        if(changes) refreshData();
    }

    const renderRequests = useCallback((showPendingOnly) => {
        const filteredRequests = requests.filter(a => {
            return [false, 'false'].includes(showPendingOnly) || a.status === 'Pending';
        });
        const columns = getColumnsRequests(handleShowModal);
        return(
            <Table
                tableName="appointments-memberRequests"
                data={filteredRequests}
                columns={columns}
                defaultSorted={defaultSorted}
                rowsPerPageInitial={20}
                emptyMessage="No requests were found"
            />
        );

    }, [requests]);

    if(apiError) return <div className="mp-text-mpLRed">{apiError}</div>
    return (
        <div style={{ minHeight: "15rem" }}>
            <Formik
                enableReinitialize
                initialValues={{
                    showPendingOnly: true
                }}
                onSubmit={() => null}
            >
                {formik => (
                    <>
                        <h3>Your Requests</h3>

                        <br/>
                        
                        <div className="flex flex-row gap-x-4">
                            <Switch
                                id="member-requests-showPendingOnly"
                                name="showPendingOnly"
                                onChange={formik.handleChange}
                                checked={formik.values.showPendingOnly}
                                color="mpDBlue"
                                textPosition="after"
                                label="Show Pending Only"
                            />

                            <Button
                                color="lte-mpLBlue"
                                onClick={() => handleShowModal('create')}
                            >
                                + New Request
                            </Button>
                        </div>

                        <br/>

                        {loading || !hasLoaded ?
                            <>
                                <span className="ml-3 spinner spinner-mpDBlue" style={{ marginRight: "2rem" }}/>
                                Loading...
                            </>
                            : renderRequests(formik.values.showPendingOnly)
                        }
                    </>
                )}
            </Formik>
            {showModal &&
                <MemberRequestModal
                    mode={modalMode}
                    selectedRequest={selectedRequest}
                    onSubmitCallback={onSubmitCallback}
                />
            }
        </div>
    );
}

export default connect(null, {
    fetchMemberRequestsStudentIds
})(MemberRequests);