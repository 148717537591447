import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';

function Footer(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { version } = props;

    return (
        <div className="flex flex-row w-full bg-black text-white text-base px-8 py-3 relative">
            <div>
                ReadingPlex Member v{version}
            </div>
            <div className="ml-auto">
                &copy; {(new Date()).getFullYear()} MathPlex Tutoring LLC
            </div>
        </div>
    );
}

export default connect(null, {

})(Footer);