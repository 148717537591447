import app from '../api/app';
import Cookies from 'universal-cookie';
import DOMPurify from 'dompurify';

import { formatStrings } from '../components/functions';
import { Wait } from '../components/wait';

import { clearUserInfo } from '../app/store';

export { login, signout, forceSignout, requestPasswordReset, resetPassword } from './paths/auth';

export { fetchAdminUsersEssential } from './paths/adminUsers';
export { fetchAppointmentsMemberParentStudent } from './paths/appointments';
export { fetchCentersAll } from './paths/centers';
export { fetchMemberRequestsIds, fetchMemberRequestsStudentIds, createMemberRequest, updateMemberRequest,
    updateMemberRequestCancel } from './paths/memberRequests';
export { fetchParentByStudent, fetchMemberSelf, updateMemberSelf } from './paths/memberUsers';
export { fetchServerTime } from './paths/server';
export { fetchStudentsRelevant } from './paths/students';

export { updateConnections } from './localPaths/socket';

const cookies = new Cookies();

// Attempts the specified action with the access token currently stored in the broswer cookie.
// If the access token has expired, the refresh token is used in an attempt to get a new access token.
// If the refresh token has expired, the user will be signed out.
export const attemptAction = async (action, dispatch, params = {}) => {
    // Sanitize and escape ' for SQL use
    const sanitizedParams = JSON.parse(DOMPurify.sanitize(JSON.stringify(formatStrings(params))));

    // Attempt action with currently stored acces token
    const aToken = cookies.get('aToken');
    sanitizedParams.aToken = aToken;

    let response = { status: 999 };
    if(aToken){
        try {
            response = await action(sanitizedParams);
        } catch(e) {
            console.log(e);    
        } finally {
            if(!response || !response.status) return response || {};

            // 401 will be used for expired or otherwise invalid tokens
            if([401, 999].includes(response.status)){
                let tokenResponse = await refreshToken();

                // Try one more time if the first request failed
                // This should hopefully limit premature signouts
                if(!tokenResponse?.data?.message || tokenResponse?.status === 401){
                    console.log(11);
                    await Wait(500);
                    tokenResponse = await refreshToken();
                }

                // Token response status should either be 200, 401, or 500
                if(!tokenResponse || tokenResponse.status === 500) return tokenResponse;

                // 401 should only be be returned if the refresh token has expired
                if(tokenResponse.status === 401){
                    dispatch(clearUserInfo());
                    return response;
                }
                
                // If new token received, retry action
                const aToken = tokenResponse.data?.aToken;
                if(!aToken) return {};

                cookies.set('aToken', tokenResponse.data.aToken, { path: '/' });
                sanitizedParams.aToken = aToken;
                
                // return await action(sanitizedParams);
                const response2 = await action(sanitizedParams);
                return response2;
            } else {
                return response || {};
            }
        }
    } else {
        dispatch(clearUserInfo());
        return response;
    }
}

const refreshToken = async () => {
    const rToken = cookies.get('rToken');
    if(!rToken) return {};

    let response = null;
    try {
        response = await app.post(`/memberAuth/request-new-token`, {
            rToken,
            portal: 'rp'
        });
    } catch(e) {
        response = e.response;
        console.log(e);
    } finally {}

    return response || {};
}