import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    selectedStudent: Yup.mixed().test(
        'studentIsSeleceted',
        'Select a valid student',
        (value) => value?.value !== -1
    ),
    message: Yup.string()
        .required('Message is required')
        .max(500, 'Max 500 characters'),
});

export function getInitStudentOption(studentId, studentOptions){
    if(!studentId) return { value: -1, label: 'Please select a student...' };
    return studentOptions.find(s => s.value === studentId) || { value: -1, label: `Unknown student (UID ${studentId})`};
}