import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import MemberRequestsBodyFooter from './MemberRequestsBF';
import { checkResponse } from '../../form';

import {
    fetchMemberRequestsIds,
    fetchStudentsRelevant,
    createMemberRequest,
    updateMemberRequest,
    updateMemberRequestCancel,
} from '../../../actions';

function MemberRequests(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [memberRequest, setMemberRequest] = useState({});
    const [studentOptions, setStudentOptions] = useState([])

    const { mode, selectedRequest, onSubmitCallback, fetchMemberRequestsIds, 
        fetchStudentsRelevant, createMemberRequest, updateMemberRequest, updateMemberRequestCancel } = props;

    useEffect(() => {
        async function init(){
            const relevantStudentsRes = await fetchStudentsRelevant();
            const newRelevantStudents = relevantStudentsRes.data?.students || [];
            const newStudentOptions = newRelevantStudents.filter(s => {
                return (parseInt(s.is_rp_student) === 1 && parseInt(s.rp_active) === 1) || selectedRequest?.student === s.user_id;
            }).map(ns => ({ value: ns.user_id, label: `${ns.first_name} ${ns.last_name}` }));

            if(mode !== 'create'){
                const memberRequestRes = await fetchMemberRequestsIds({ ids: [selectedRequest?.id] });
                const newMemberRequest = memberRequestRes.data?.[0] || {};

                // The following is for delete mode
                const matchedStudent = newStudentOptions.find(s => s.obj?.user_id === newMemberRequest.student) || {};
                const name = matchedStudent.first_name || matchedStudent.last_name ? 
                    `${matchedStudent.first_name} ${matchedStudent.last_name}` : `Unknown Student (UID: ${selectedRequest?.student})`;
                newMemberRequest.studentName = name

                if(mounted.current) setMemberRequest(newMemberRequest);
            }

            if(mounted.current){
                setStudentOptions(newStudentOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const memberRequestParams = {
                    student: values.selectedStudent.value,
                    message: values.message
                };
                
                if(mode === 'create'){
                    response = await createMemberRequest(memberRequestParams);
                } else if(mode === 'edit') {
                    memberRequestParams.id = memberRequest.id;
                    response = await updateMemberRequest(memberRequestParams);
                }
            } else if(mode === 'delete') {
                response = await updateMemberRequestCancel({ id: memberRequest.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, memberRequest, createMemberRequest, updateMemberRequest, updateMemberRequestCancel]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Member Request Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <MemberRequestsBodyFooter
                    mode={mode}
                    selectedRequest={selectedRequest}
                    studentOptions={studentOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchMemberRequestsIds,
    fetchStudentsRelevant,
    createMemberRequest,
    updateMemberRequest,
    updateMemberRequestCancel,
})(MemberRequests);