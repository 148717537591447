import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { SelectSingle, DateRangeSelector } from '../../../../components/form';
import { formatDateApi, convertApiToDate } from '../../../../components/functions';
import { Table } from '../../../../components/table';
import { getColumnsPast } from '../table/getColumns';

const defaultOption = { value: 'all', label: 'All' };
const defaultSorted = { dataField: 'date_time', order: 'desc' };

const start = new Date();
start.setMonth(start.getMonth() - 1);
const startApi = formatDateApi(start);
const end = new Date();
const endApi = formatDateApi(end);

function AppointmentInfo(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const { loading, viewMode, studentOptions, relevantAppointments, apiError } = props;

    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [drsValid, setDrsValid] = useState(true);

    const filterAppointments = useCallback((selectedStudent, startDate, endDate) => {
        if(!drsValid) return;

        const studentId = selectedStudent.value || selectedStudent;
        const startDateObj = convertApiToDate(startDate);
        const endDateObj = convertApiToDate(endDate);
        endDateObj.setDate(endDateObj.getDate() + 1);
        endDateObj.setSeconds(endDateObj.getSeconds() - 1);
        
        const newFilteredAppointments = relevantAppointments.filter(a => {
            const aptDate = new Date(a.date_time);
            if(studentId !== 'all'  && studentId !== a.student) return false;
            if(aptDate < startDateObj) return false;
            if(aptDate > endDateObj) return false;
            return true;
        });

        if(mounted.current){
            setFilteredAppointments(newFilteredAppointments);
        }
    }, [relevantAppointments, drsValid]);
    useEffect(() => {
        (async function init(){
            const formik = formRef?.current || {};
            const values = formik.values || {};
            const { selectedStudent, startDate, endDate } = values;
            filterAppointments(selectedStudent, startDate, endDate);
        })();
    }, [filterAppointments]);

    const columns = useMemo(() => getColumnsPast(viewMode), [viewMode]);

    if(apiError) return <div className="mp-text-mpLRed">{apiError}</div>
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    selectedStudent: defaultOption,
                    startDate: startApi,
                    endDate: endApi,
                }}
                onSubmit={() => null}
                innerRef={formRef}
            >
                {formik => (
                    <>
                        <h3>Appointment History</h3>
                        <br/>
                        { viewMode === 'web' ?
                            <>
                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <SelectSingle
                                            id="upcoming-appointments-student"
                                            label="Student"
                                            name="selectedStudent"
                                            value={formik.values.selectedStudent}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterAppointments(e.target.value, formik.values.startDate, formik.values.endDate);
                                            }}
                                            disabled={loading}
                                            options={[defaultOption, ...studentOptions]}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-2/3">
                                        <DateRangeSelector
                                            id="upcoming-appointments-drs-1"
                                            startName="startDate"
                                            endName="endDate"
                                            startLabel="Start Date"
                                            endLabel="End Date"
                                            startValue={formik.values.startDate}
                                            endValue={formik.values.endDate}
                                            defaultValid={true}
                                            onStartChange={(e) => {
                                                formik.handleChange(e);
                                                filterAppointments(formik.values.selectedStudent, e.target.value, formik.values.endDate);
                                            }}
                                            onEndChange={(e) => {
                                                formik.handleChange(e);
                                                filterAppointments(formik.values.selectedStudent, formik.values.startDate, e.target.value);
                                            }}
                                            onChangeValidation={setDrsValid}
                                        />
                                    </div>
                                </div>
                            </>
                                : viewMode === 'mobile' ?
                            <>
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <div className="text-sm text-mpLGrey-2">Student</div>
                                    <SelectSingle
                                        id="upcoming-appointments-student"
                                        label="Student"
                                        name="selectedStudent"
                                        value={formik.values.selectedStudent}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterAppointments(e.target.value, formik.values.startDate, formik.values.endDate);
                                        }}
                                        disabled={loading}
                                        options={[defaultOption, ...studentOptions]}
                                    />
                                </div>
                                <br/>
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <DateRangeSelector
                                        id="upcoming-appointments-drs-1"
                                        startName="startDate"
                                        endName="endDate"
                                        startLabel="Start Date"
                                        endLabel="End Date"
                                        startValue={formik.values.startDate}
                                        endValue={formik.values.endDate}
                                        defaultValid={true}
                                        onStartChange={(e) => {
                                            formik.handleChange(e);
                                            filterAppointments(formik.values.selectedStudent, e.target.value, formik.values.endDate);
                                        }}
                                        onEndChange={(e) => {
                                            formik.handleChange(e);
                                            filterAppointments(formik.values.selectedStudent, formik.values.startDate, e.target.value);
                                        }}
                                        onChangeValidation={setDrsValid}
                                    />
                                </div>
                            </> : null
                        }

                        <br/>

                        {loading ?
                            <>
                                <div>Loading...</div>
                            </>
                            : (
                                <div className="flex flex-row gap-x-4">
                                    <Table
                                        tableName="appointments-info"
                                        data={filteredAppointments}
                                        columns={columns}
                                        defaultSorted={defaultSorted}
                                        rowsPerPageInitial={10}
                                        emptyMessage="No appointments were found for this student and date range"
                                    />
                                </div>
                            )
                        }
                    </>
                )}
            </Formik>
        </>
    )
}

export default connect(null, {

})(AppointmentInfo);