import React, { useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { getColumnsUpcoming } from '../table/getColumns';
import { SelectSingle } from '../../../../components/form';
import { Table } from '../../../../components/table';

const defaultOption = { value: 'all', label: 'All' };
const defaultSorted = { dataField: 'date_time', order: 'asc' };

function UpcomingAppointments(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { loading, viewMode, studentOptions, centerOptions, relevantAppointments, apiError } = props;

    const renderAppointments = useCallback((selectedStudent, selectedCenter) => {
        const filteredAppointments = relevantAppointments.filter(a => {
            const studentMatch = selectedStudent.value === 'all' || a.student === selectedStudent.value;
            const centerMatch = selectedCenter.value === 'all' || parseInt(a.center) === parseInt(selectedCenter.value);
            return studentMatch && centerMatch && a.isInFuture;
        });
        if(!filteredAppointments.length) return <div>No upcoming appointments match the selected criteria</div>;
        else {
            const columns = getColumnsUpcoming();
            return(
                <Table
                    tableName="appointments-upcoming"
                    data={filteredAppointments}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    rowsPerPageInitial={10}
                    emptyMessage="No upcoming appointments were found"
                />
            );
        }
    }, [relevantAppointments]);

    if(apiError) return <div className="mp-text-mpLRed">{apiError}</div>
    return (
        <div style={{ minHeight: "15rem" }}>
            <Formik
                enableReinitialize
                initialValues={{
                    selectedStudent: defaultOption,
                    selectedCenter: defaultOption
                }}
                onSubmit={() => null}
            >
                {formik => (
                    <>
                        <h3>Upcoming Appointments</h3>
                        <br/>
                        { viewMode === 'web' ? 
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                    <SelectSingle
                                        id="upcoming-appointments-student"
                                        label="Student"
                                        name="selectedStudent"
                                        value={formik.values.selectedStudent}
                                        onChange={formik.handleChange}
                                        disabled={loading}
                                        options={[defaultOption, ...studentOptions]}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                    <SelectSingle
                                        id="upcoming-appointments-center"
                                        label="Center"
                                        name="selectedCenter"
                                        value={formik.values.selectedCenter}
                                        onChange={formik.handleChange}
                                        disabled={loading}
                                        options={[defaultOption, ...centerOptions]}
                                    />
                                </div>
                            </div> : viewMode === 'mobile' ?
                            <>
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <div className="font-sm text-mpLGrey-2">Student</div>
                                    <SelectSingle
                                        id="upcoming-appointments-student"
                                        label="Student"
                                        name="selectedStudent"
                                        value={formik.values.selectedStudent}
                                        onChange={formik.handleChange}
                                        disabled={loading}
                                        options={[defaultOption, ...studentOptions]}
                                    />
                                </div>
                                <br/>
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <div className="font-sm text-mpLGrey-2">Center</div>
                                    <SelectSingle
                                        id="upcoming-appointments-center"
                                        label="Center"
                                        name="selectedCenter"
                                        value={formik.values.selectedCenter}
                                        onChange={formik.handleChange}
                                        disabled={loading}
                                        options={[defaultOption, ...centerOptions]}
                                    />
                                </div>
                            </> : null
                        }

                        <br/>

                        {loading ?
                            <>
                                <div>Loading...</div>
                            </>
                            : renderAppointments(formik.values.selectedStudent, formik.values.selectedCenter)
                        }
                    </>
                )}
            </Formik>
        </div>
    );
}

export default connect(null, {

})(UpcomingAppointments);